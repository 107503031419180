import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('jwt');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export default fetchClient();